//products.js
import CarbonSteel from '../src/assets/images/wedeal/Carbon-Steel.webp'
import Carbonsteel2 from '../src/assets/images/home/material/new/steel.jpg'
import Carbonsteel3 from '../src/assets/images/wedeal/carbonsteel3.jpg'
import Carbonsteel4 from '../src/assets/images/wedeal/carbonsteel4.webp'
import Carbonsteel5 from '../src/assets/images/wedeal/carbonsteel5.webp'

import stain1 from '../src/assets/images/material/new/stainlesssteel/1.jpg'
import stain2 from '../src/assets/images/material/new/stainlesssteel/2.jpg'
import stain3 from '../src/assets/images/material/new/stainlesssteel/3.jpg'
import stain4 from '../src/assets/images/material/new/stainlesssteel/4.jpg'
import stain5 from '../src/assets/images/material/new/stainlesssteel/5.jpg'

import alloy1 from '../src/assets/images/material/new/alloysteel/1.jpg'
import alloy2 from '../src/assets/images/material/new/alloysteel/2.jpg'
import alloy3 from '../src/assets/images/material/new/alloysteel/3.jpg'
import alloy4 from '../src/assets/images/material/new/alloysteel/4.avif'
import alloy5 from '../src/assets/images/material/new/alloysteel/5.jpg'

import methand1 from '../src/assets/images/material/new/mattelhand/1.jpg'
import methand2 from '../src/assets/images/material/new/mattelhand/2.jpg'
import methand3 from '../src/assets/images/material/new/mattelhand/3.jpg'
import methand4 from '../src/assets/images/material/new/mattelhand/4.jpg'
import methand5 from '../src/assets/images/material/new/mattelhand/5.jpg'

import woodhand1 from '../src/assets/images/material/new/woodenhand/1.jpg'
import woodhand2 from '../src/assets/images/material/new/woodenhand/2.jpg'
import woodhand3 from '../src/assets/images/material/new/woodenhand/3.jpg'
import woodhand4 from '../src/assets/images/material/new/woodenhand/4.jpg'
import woodhand5 from '../src/assets/images/material/new/woodenhand/5.jpg'

import texthand1 from '../src/assets/images/material/new/textilehand/1.jpg'
import texthand2 from '../src/assets/images/material/new/textilehand/2.jpg'
import texthand3 from '../src/assets/images/material/new/textilehand/3.jpg'
import texthand4 from '../src/assets/images/material/new/textilehand/4.avif'
import texthand5 from '../src/assets/images/material/new/textilehand/5.jpg'

import steelp1 from '../src/assets/images/material/new/steelpipe/1.avif'
import steelp2 from '../src/assets/images/material/new/steelpipe/2.jpg'
import steelp3 from '../src/assets/images/material/new/steelpipe/3.jpg'
import steelp4 from '../src/assets/images/material/new/steelpipe/4.jpg'
import steelp5 from '../src/assets/images/material/new/steelpipe/5.jpg'

import pipef1 from '../src/assets/images/material/new/pipeandfitting/1.avif'
import pipef2 from '../src/assets/images/material/new/pipeandfitting/2.jpg'
import pipef3 from '../src/assets/images/material/new/pipeandfitting/3.jpg'
import pipef4 from '../src/assets/images/material/new/pipeandfitting/4.jpg'
import pipef5 from '../src/assets/images/material/new/pipeandfitting/5.jpg'

import safty1 from '../src/assets/images/material/new/safty/1.avif'
import safty2 from '../src/assets/images/material/new/safty/2.jpg'
import safty3 from '../src/assets/images/material/new/safty/3.jpg'
import safty4 from '../src/assets/images/material/new/safty/4.jpg'
import safty5 from '../src/assets/images/material/new/safty/5.jpg'

import personalp1 from '../src/assets/images/material/new/personalprotective/1.avif'
import personalp2 from '../src/assets/images/material/new/personalprotective/2.avif'
import personalp3 from '../src/assets/images/material/new/personalprotective/3.jpg'
import personalp4 from '../src/assets/images/material/new/personalprotective/4.jpg'
import personalp5 from '../src/assets/images/material/new/personalprotective/5.jpg'

import ball1 from '../src/assets/images/material/new/ballandnuts/1.jpg'
import ball2 from '../src/assets/images/material/new/ballandnuts/2.jpg'
import ball3 from '../src/assets/images/material/new/ballandnuts/3.jpg'
import ball4 from '../src/assets/images/material/new/ballandnuts/4.avif'
import ball5 from '../src/assets/images/material/new/ballandnuts/5.jpg'
import ball6 from '../src/assets/images/material/new/ballandnuts/6.jpg'
import ball7 from '../src/assets/images/material/new/ballandnuts/7.jpg'
import ball8 from '../src/assets/images/material/new/ballandnuts/8.jpg'

import wire from '../src/assets/images/home/material/wire.webp'

import grafting1 from '../src/assets/images/material/new/wireandmesh/1.jpg'
import grafting2 from '../src/assets/images/material/new/wireandmesh/2.jpg'
import grafting3 from '../src/assets/images/material/new/wireandmesh/3.jpg'
import grafting4 from '../src/assets/images/material/new/wireandmesh/4.jpeg'
import grafting5 from '../src/assets/images/material/new/wireandmesh/5.jpg'

import steelsheet1 from '../src/assets/images/material/new/stillsheet/1.jpg'
import steelsheet2 from '../src/assets/images/material/new/stillsheet/2.jpg'
import steelsheet3 from '../src/assets/images/material/new/stillsheet/3.jpg'
import steelsheet4 from '../src/assets/images/material/new/stillsheet/4.jpg'
import steelsheet5 from '../src/assets/images/material/new/stillsheet/5.jpg'
import steelsheet6 from '../src/assets/images/material/new/stillsheet/6.avif'
import steelsheet7 from '../src/assets/images/material/new/stillsheet/7.jpg'
import steelsheet8 from '../src/assets/images/material/new/stillsheet/8.jpg'
import steelsheet9 from '../src/assets/images/material/new/stillsheet/9.avif'


import Client1 from '../src/assets/images/clients/1.png';
import Client2 from '../src/assets/images/clients/2.png';
import Client3 from '../src/assets/images/clients/3.png';
import Client4 from '../src/assets/images/clients/4.png';
import Client5 from '../src/assets/images/clients/5.png';
import Client6 from '../src/assets/images/clients/6.png';
import Client7 from '../src/assets/images/clients/7.png';
import Client8 from '../src/assets/images/clients/8.png';
import Client9 from '../src/assets/images/clients/9.png';
import Client10 from '../src/assets/images/clients/10.png';
import Client11 from '../src/assets/images/clients/11.png';
import Client12 from '../src/assets/images/clients/12.png';
import Client13 from '../src/assets/images/clients/13.png';
import Client14 from '../src/assets/images/clients/14.png';
import Client15 from '../src/assets/images/clients/15.png';
import Client16 from '../src/assets/images/clients/16.png';
import Client17 from '../src/assets/images/clients/17.png';
import Client18 from '../src/assets/images/clients/18.png';
import Client19 from '../src/assets/images/clients/19.png';
import Client20 from '../src/assets/images/clients/20.png';
import Client21 from '../src/assets/images/clients/21.png';
import Client22 from '../src/assets/images/clients/22.png';
import Client23 from '../src/assets/images/clients/23.png';
import Client24 from '../src/assets/images/clients/24.png';


import C1 from '../src/assets/images/clients/new/1.png';
import C2 from '../src/assets/images/clients/new/2.png';
import C3 from '../src/assets/images/clients/new/3.webp';
import C4 from '../src/assets/images/clients/new/4.png';
import C5 from '../src/assets/images/clients/new/5.jpg';
import C6 from '../src/assets/images/clients/new/6.png';
import C7 from '../src/assets/images/clients/new/7.png';
import C8 from '../src/assets/images/clients/new/8.jpg';
import C9 from '../src/assets/images/clients/new/9.jpg';


import rubber1 from '../src/assets/images/material/new/rubber/1.jpg'
import rubber2 from '../src/assets/images/material/new/rubber/2.jpg'
import rubber3 from '../src/assets/images/material/new/rubber/3.jpg'
import rubber4 from '../src/assets/images/material/new/rubber/4.webp'
import rubber5 from '../src/assets/images/material/new/rubber/5.jpg'
import rubber6 from '../src/assets/images/material/new/rubber/6.jpg'
import rubber7 from '../src/assets/images/material/new/rubber/7.jpg'
import rubber8 from '../src/assets/images/material/new/rubber/8.jpg'
import rubber9 from '../src/assets/images/material/new/rubber/9.jpg'

import Logo2 from '../src/assets/images/home/surya-logo.webp';
import Logo3 from '../src/assets/images/home/tata-steel-logo.webp';
import Logo5 from '../src/assets/images/home/vizac-steels-logo.webp';
import steel from '../src/assets/images/home/material/new/steel.jpg';
import Pipe from '../src/assets/images/home/material/pipe.webp';
import Handicraftt from '../src/assets/images/home/material/handicraft.jpg';
import Saftyitem from '../src/assets/images/home/material/new/safty.avif';
import Fastener from '../src/assets/images/home/material/new/fasters.jpg';
import Wire from '../src/assets/images/home/material/new/wiremesh.jpg';
import Rubber from '../src/assets/images/home/material/new/rubber.jpg';
import Refactory from '../src/assets/images/home/material/new/anchore.jpeg';
import StainlessSteel from '../src/assets/images/home/material/stainless.webp';
import Hroil from '../src/assets/images/home/material/hroil.webp';
import NonFerrousMetals from '../src/assets/images/home/material/NonFerrousMetals.jpg';
import Texttile from '../src/assets/images/home/material/texttile.webp';
import InstaReel from '../src/assets/images/home/material/new/reel.jpg';
import Food from '../src/assets/images/home/packaging/food.jpg';
import Pump from '../src/assets/images/home/material/new/pump.jpg';
import Cable from '../src/assets/images/home/material/new/wire.jpg';
import Pharmaceuticals from '../src/assets/images/home/packaging/pharmaproduct.jpg';
import Spirits from '../src/assets/images/home/material/sprits.jpg';
import Sweet from '../src/assets/images/home/packaging/sweet.avif';
import PersonalCare from '../src/assets/images/home/packaging/personal.avif';
import Fashion from '../src/assets/images/home/material/fashion.jpg';
import ElectronicsandSoftware from '../src/assets/images/home/material/electricandsoftware.jpg';
import img1 from '../src/assets/images/materials/steel/2.webp'
import img2 from '../src/assets/images/materials/pipe/4.png'
import img3 from '../src/assets/images/materials/safetyitems/2.webp'
import img4 from '../src/assets/images/materials/fastener/3.webp'
import img5 from '../src/assets/images/materials/wire/3.webp'
import img6 from '../src/assets/images/materials/steel/4.webp'
import img7 from '../src/assets/images/materials/metals/3.webp'
import img8 from '../src/assets/images/materials/spares/5.webp'
import img9 from '../src/assets/images/materials/Food&Condiments/main.jpg'
import img10 from '../src/assets/images/materials/spares/4.webp'
import img11 from '../src/assets/images/materials/cables/2.png'
import img12 from '../src/assets/images/materials/transmission/6.jpg'
import img13 from '../src/assets/images/materials/seals/4.webp'
import Logo7 from '../src/assets/images/home/jindal-trade-mark-logo.webp';
import Logo6 from '../src/assets/images/home/kamdenu-group-logo.webp';
import Logo1 from '../src/assets/images/home/sail-logo.webp';
import Logo4 from '../src/assets/images/home/apollo-tube-logo.webp';

import hrcoil1 from '../src/assets/images/material/new/hrcoils/1.jpg'
import hrcoil2 from '../src/assets/images/material/new/hrcoils/2.avif'
import hrcoil3 from '../src/assets/images/material/new/hrcoils/4.webp'
import hrcoil4 from '../src/assets/images/material/new/hrcoils/5.webp'
import hrcoil5 from '../src/assets/images/material/new/hrcoils/6.jpg'

import aluminium1 from '../src/assets/images/material/new/nonmetal/1.jpg'
import aluminium2 from '../src/assets/images/material/new/nonmetal/2.jpg'
import aluminium3 from '../src/assets/images/material/new/nonmetal/3.jpg'
import aluminium4 from '../src/assets/images/material/new/nonmetal/4.jpg'
import aluminium5 from '../src/assets/images/material/new/nonmetal/5.jpg'
import aluminium6 from '../src/assets/images/material/new/nonmetal/6.jpg'
import aluminium7 from '../src/assets/images/material/new/nonmetal/7.jpg'


import texttile1 from '../src/assets/images/material/new/texttile/1.jpg'
import texttile2 from '../src/assets/images/material/new/texttile/2.jpg'
import texttile3 from '../src/assets/images/material/new/texttile/3.jpg'
import texttile4 from '../src/assets/images/material/new/texttile/4.jpg'
import texttile5 from '../src/assets/images/material/new/texttile/5.avif'

import food1 from '../src/assets/images/material/new/food/1.avif'
import food2 from '../src/assets/images/material/new/food/2.jpg'
import food3 from '../src/assets/images/material/new/food/3.jpg'
import food4 from '../src/assets/images/material/new/food/4.jpg'
import food5 from '../src/assets/images/material/new/food/5.jpg'
import food6 from '../src/assets/images/material/new/food/6.jpg'
import food7 from '../src/assets/images/material/new/food/7.jpg'
import food8 from '../src/assets/images/material/new/food/8.jpg'

import instareels2 from '../src/assets/images/material/new/instareels/reels2.jpeg'
import instareels3 from '../src/assets/images/material/new/instareels/reels3.jpg'
import instareels4 from '../src/assets/images/material/new/instareels/reels4.jpg'


const ProductData = [
  {
    category: 'Steels',
    subcategories: [
      {
        name: 'Carbon Steel',
        description: 'Versatile and widely used in various industries for its strength and durability.',
        images: [
          {
            original: CarbonSteel,
            thumbnail: CarbonSteel
          },
          {
            original: Carbonsteel2,
            thumbnail: Carbonsteel2
          },
          {
            original: Carbonsteel3,
            thumbnail: Carbonsteel3
          },
          {
            original: Carbonsteel4,
            thumbnail: Carbonsteel4
          },
          {
            original: Carbonsteel5,
            thumbnail: Carbonsteel5
          },
        ],
      },
      {
        name: 'Stainless Steel',
        description: 'Known for its corrosion resistance, making it ideal for applications in harsh environments.',
        images: [
          {
            original: stain1,
            thumbnail: stain1
          },
          {
            original: stain2,
            thumbnail: stain2
          },
          {
            original: stain3,
            thumbnail: stain3
          },
          {
            original: stain4,
            thumbnail: stain4
          },
          {
            original: stain5,
            thumbnail: stain5
          },
        ],
      },
      {
        name: 'Alloy Steel',
        description: 'Offering enhanced mechanical properties for specialized industrial requirements.',
        images: [
          {
            original: alloy1,
            thumbnail: alloy1
          },
          {
            original: alloy2,
            thumbnail: alloy2
          },
          {
            original: alloy3,
            thumbnail: alloy3
          },
          {
            original: alloy4,
            thumbnail: alloy4
          },
          {
            original: alloy5,
            thumbnail: alloy5
          },
        ],
      }
    ],

    products: "Our steel offerings encompass a range of grades, dimensions, and finishes, catering to the specific needs of construction, manufacturing, and engineering industries. From structural steels to precision components, our products adhere to international standards, ensuring reliability and performance in diverse applications."
  },
  {
    category: 'Import',
    subcategories: [
      {
        name: 'Hot Rolled (HR) Coils',
        description: 'Coils produced through hot rolling for various applications.',
        images: [
          {
            original: hrcoil1,
            thumbnail: hrcoil1
          },
          {
            original: hrcoil2,
            thumbnail: hrcoil2
          },
        ],
      },
      {
        name: 'Pre-painted Galvanized Iron (PPGI) Coils',
        description: 'Coated coils for enhanced corrosion resistance and aesthetics.',
        images: [
          {
            original: hrcoil3,
            thumbnail: hrcoil3
          },
          {
            original: hrcoil4,
            thumbnail: hrcoil4
          },
          {
            original: hrcoil5,
            thumbnail: hrcoil5
          },
        ],
      },
      {
        name: 'Cold Rolled (CR) Coils',
        description: 'Precision-rolled coils for critical applications.',
        images: [
          {
            original: hrcoil2,
            thumbnail: hrcoil2
          },
        ],
      }
    ],
    products: "Our coils cater to the diverse needs of industries ranging from construction to automotive. With a focus on dimensional accuracy and surface finish, our HR, PPGI, and CR coils ensure optimal performance in various manufacturing processes."
  },
  {
    category: 'Export',
    subcategories: [
      {
        name: 'Handicrafts and Antiques',
        description: 'Our collection of handicrafts and antiques encompasses a wide range of items, from traditional artifacts to contemporary designs. These pieces showcase the craftsmanship and cultural richness of India, making them ideal for decorative and collector\'s purposes.',
        images: [
          // Images here
        ],
        subcategories: [
          {
            name: 'Metal Handicrafts',
            description: 'Intricately designed pieces crafted from metals such as brass, copper, and aluminum.',
            images: [
              {
                original: methand1,
                thumbnail: methand1
              },
              {
                original: methand2,
                thumbnail: methand2
              },
              {
                original: methand3,
                thumbnail: methand3
              },
              {
                original: methand4,
                thumbnail: methand4
              },
              {
                original: methand5,
                thumbnail: methand5
              },
            ],
          },
          {
            name: 'Wooden Handicrafts',
            description: 'Traditional and contemporary designs showcasing the rich craftsmanship of Indian artisans.',
            images: [
              {
                original: woodhand1,
                thumbnail: woodhand1
              },
              {
                original: woodhand2,
                thumbnail: woodhand2
              },
              {
                original: woodhand3,
                thumbnail: woodhand3
              },
              {
                original: woodhand4,
                thumbnail: woodhand4
              },
              {
                original: woodhand5,
                thumbnail: woodhand5
              },
            ],
          },
          {
            name: 'Textile Handicrafts',
            description: 'Embroidered and woven textiles reflecting the cultural diversity of India.',
            images: [
              {
                original: texthand1,
                thumbnail: texthand1
              },
              {
                original: texthand2,
                thumbnail: texthand2
              },
              {
                original: texthand3,
                thumbnail: texthand3
              },
              {
                original: texthand4,
                thumbnail: texthand4
              },
              {
                original: texthand5,
                thumbnail: texthand5
              },
            ],
          }

        ],
      },
      {
        name: 'Pipe and Pipe-fitting',
        description: 'Versatile and widely used in various industries for its strength and durability.',
        images: [
          // Images here
        ],
        subcategories: [
          {
            name: 'Steel Pipes',
            description: 'Seamless, ERW, and welded pipes suitable for various industrial applications.',
            images: [
              {
                original: steelp1,
                thumbnail: steelp1
              },
              {
                original: steelp2,
                thumbnail: steelp2
              },
              {
                original: steelp3,
                thumbnail: steelp3
              },
              {
                original: steelp4,
                thumbnail: steelp4
              },
              {
                original: steelp5,
                thumbnail: steelp5
              },
            ],
          },
          {
            name: 'Pipe Fittings',
            description: 'Elbows, tees, reducers, and more, ensuring precise connections in plumbing and construction.',
            images: [
              {
                original: pipef1,
                thumbnail: pipef1
              },
              {
                original: pipef2,
                thumbnail: pipef2
              },
              {
                original: pipef3,
                thumbnail: pipef3
              },
              {
                original: pipef4,
                thumbnail: pipef4
              },
              {
                original: pipef5,
                thumbnail: pipef5
              },
            ],
          }
        ],
      },
      {
        name: 'Textiles',
        description: 'Our textiles cover a spectrum of materials and designs, meeting the needs of the fashion, home decor, and industrial sectors. From natural fibers like cotton to luxurious silk, our textiles offer quality and versatility.',
        images: [
          // Images here
        ],
        subcategories: [
          {
            name: 'Cotton Fabrics',
            description: 'Breathable and versatile textiles suitable for apparel and home furnishings.',
            images: [
              {
                original: texttile1,
                thumbnail: texttile1
              },
              {
                original: texttile2,
                thumbnail: texttile2
              },
            ],
          },
          {
            name: 'Silk Fabrics',
            description: 'Luxurious and smooth fabrics for high-end fashion and home decor.',
            images: [
              {
                original: texttile3,
                thumbnail: texttile3
              },
              {
                original: texttile4,
                thumbnail: texttile4
              },
              {
                original: texttile5,
                thumbnail: texttile5
              },
            ],
          },
          {
            name: 'Woven and Knitted Fabrics',
            description: 'Diverse fabrics for various applications, including industrial use.',
            images: [
              {
                original: texttile1,
                thumbnail: texttile1
              },
              {
                original: texttile2,
                thumbnail: texttile2
              },
            ],
          }
        ],
      },
      {
        name: 'Insta Reels Making Accessories',
        description: 'We provide specialized accessories for Insta Reels manufacturing, ensuring efficiency and precision in the production process. From cutting tools to components, our accessories contribute to the seamless production of Insta Reels.',
        images: [
          {
            original: instareels2,
            thumbnail: instareels2
          },
          {
            original: instareels3,
            thumbnail: instareels3
          },
          {
            original: instareels4,
            thumbnail: instareels4
          },
        ],

      },
      {
        name: 'Food Products',
        description: 'Our food products capture the flavors of India, offering a diverse range of spices, canned goods, snacks, and confectionery. Sourced from trusted suppliers, our food products meet stringent quality standards, ensuring a delightful culinary experience.',
        images: [
          // Images here
        ],
        subcategories: [
          {
            name: 'Spices and Seasonings',
            description: 'Authentic Indian spices for culinary excellence.',
            images: [
              {
                original: food1,
                thumbnail: food1
              },
              {
                original: food2,
                thumbnail: food2
              },
              {
                original: food3,
                thumbnail: food3
              },
            ],
          },
          {
            name: 'Canned and Preserved Foods',
            description: 'Convenient and high-quality packaged food products.',
            images: [
              {
                original: food4,
                thumbnail: food4
              },
              {
                original: food5,
                thumbnail: food5
              },
              {
                original: food6,
                thumbnail: food6
              },
              {
                original: food7,
                thumbnail: food7
              },
            ],
          },
          {
            name: 'Snacks and Confectionery',
            description: 'Delightful treats for all occasions.',
            images: [
              {
                original: food8,
                thumbnail: food8
              },
            ],
          }
        ],
      },
      {
        name: 'Safety Items',
        description: 'Our collection of handicrafts and antiques encompasses a wide range of items, from traditional artifacts to contemporary designs. These pieces showcase the craftsmanship and cultural richness of India, making them ideal for decorative and collector\'s purposes.',
        images: [
          // Images here
        ],
        subcategories: [
          {
            name: 'Personal Protective Equipment (PPE)',
            description: 'Helmets, gloves, safety glasses, and other gear to ensure worker safety.',
            images: [
              {
                original: personalp1,
                thumbnail: personalp1
              },
              {
                original: personalp2,
                thumbnail: personalp2
              },
              {
                original: personalp3,
                thumbnail: personalp3
              },
              {
                original: personalp4,
                thumbnail: personalp4
              },
              {
                original: personalp5,
                thumbnail: personalp5
              },
            ],
          },
          {
            name: 'Safety Signs and Labels',
            description: 'Clearly communicate safety instructions and warnings in the workplace.',
            images: [
              {
                original: safty1,
                thumbnail: safty1
              },
              {
                original: safty2,
                thumbnail: safty2
              },
              {
                original: safty3,
                thumbnail: safty3
              },
              {
                original: safty4,
                thumbnail: safty4
              },
              {
                original: safty5,
                thumbnail: safty5
              },
            ],
          }
        ],
      },
      {
        name: 'Fasteners',
        description: 'Our collection of handicrafts and antiques encompasses a wide range of items, from traditional artifacts to contemporary designs. These pieces showcase the craftsmanship and cultural richness of India, making them ideal for decorative and collector\'s purposes.',
        images: [
          // Images here
        ],
        subcategories: [
          {
            name: 'Bolts and Nuts',
            description: 'High-strength fastening solutions for construction and industrial applications.',
            images: [
              {
                original: ball6,
                thumbnail: ball6
              },
              {
                original: ball2,
                thumbnail: ball2
              },
              {
                original: ball7,
                thumbnail: ball7
              },
              {
                original: ball8,
                thumbnail: ball8
              },
              {
                original: ball5,
                thumbnail: ball5
              },
            ],
          },
          {
            name: 'Screws and Washers',
            description: 'Versatile fasteners suitable for various materials and applications.',
            images: [
              {
                original: ball1,
                thumbnail: ball1
              },
              {
                original: ball2,
                thumbnail: ball2
              },
              {
                original: ball3,
                thumbnail: ball3
              },
              {
                original: ball4,
                thumbnail: ball4
              },
              {
                original: ball5,
                thumbnail: ball5
              },
            ],
          }
        ],
      },
      {
        name: 'Wire Mesh',
        description: 'Our collection of handicrafts and antiques encompasses a wide range of items, from traditional artifacts to contemporary designs. These pieces showcase the craftsmanship and cultural richness of India, making them ideal for decorative and collector\'s purposes.',
        images: [
          // Images here
        ],
        subcategories: [
          {
            name: 'Wire Mesh',
            description: 'Versatile mesh solutions for filtration, sieving, and reinforcement applications.',
            images: [
              {
                original: wire,
                thumbnail: wire
              }
            ],
          },
          {
            name: 'Gratings',
            description: 'Structural gratings for flooring, stair treads, and industrial platforms.',
            images: [
              {
                original: grafting1,
                thumbnail: grafting1
              },
              {
                original: grafting2,
                thumbnail: grafting2
              }
            ],
          },
          {
            name: 'Perforated Sheets',
            description: 'Precision perforated metal sheets for architectural and industrial applications.',
            images: [
              {
                original: grafting3,
                thumbnail: grafting3
              },
              {
                original: grafting2,
                thumbnail: grafting2
              },
              {
                original: grafting1,
                thumbnail: grafting1
              },
            ],
          },
          {
            name: 'Vibrating Screens',
            description: 'Efficient screening solutions for various industries.',
            images: [
              {
                original: grafting4,
                thumbnail: grafting4
              },
              {
                original: grafting5,
                thumbnail: grafting5
              },
            ],
          }
        ],
      },
      {
        name: 'Rubber Product',
        description: 'Our rubber and synthetic rubber products offer reliable solutions for sealing, damping, and conveying applications. With a focus on quality and performance, these products contribute to the efficiency and safety of various industrial processes.',
        images: [
          // Images here
        ],
        subcategories: [
                {
                  name: 'Rubber Sheets',
                  description: 'Versatile sheets for sealing, insulation, and protection.',
                  images: [
                    {
                      original: rubber1,
                      thumbnail: rubber1
                    },
                    {
                      original: rubber2,
                      thumbnail: rubber2
                    },
                    {
                      original: rubber3,
                      thumbnail: rubber3
                    },
                  ],
                },
                {
                  name: 'Rubber Hoses',
                  description: 'Flexible hoses for conveying fluids in industrial applications.',
                  images: [
                    {
                      original: rubber4,
                      thumbnail: rubber4
                    },
                    {
                      original: rubber5,
                      thumbnail: rubber5
                    },
                  ],
                },
                {
                  name: 'Synthetic Rubber Products',
                  description: 'Specialized rubber compounds for diverse industrial uses.',
                  images: [
                    {
                      original: rubber6,
                      thumbnail: rubber6
                    },
                    {
                      original: rubber7,
                      thumbnail: rubber7
                    },
                    {
                      original: rubber8,
                      thumbnail: rubber8
                    },
                    {
                      original: rubber9,
                      thumbnail: rubber9
                    },
                  ],
                }
              ],
      },
      {
        name: 'Non Ferrous Metals',
        description: 'Our rubber and synthetic rubber products offer reliable solutions for sealing, damping, and conveying applications. With a focus on quality and performance, these products contribute to the efficiency and safety of various industrial processes.',
        images: [
          // Images here
        ],
        subcategories: [
                {
                  name: 'Aluminum',
                  description: 'Lightweight and corrosion-resistant metal for diverse applications.',
                  images: [
                    {
                      original: aluminium1,
                      thumbnail: aluminium1
                    },
                    {
                      original: aluminium2,
                      thumbnail: aluminium2
                    },
                    {
                      original: aluminium3,
                      thumbnail: aluminium3
                    },
                  ],
                },
                {
                  name: 'Copper',
                  description: 'Conductive metal used in electrical and electronic applications.',
                  images: [
                    {
                      original: aluminium4,
                      thumbnail: aluminium4
                    },
                    {
                      original: aluminium5,
                      thumbnail: aluminium5
                    },
                  ],
                },
                {
                  name: 'Brass',
                  description: 'Alloy of copper and zinc, offering a balance of strength and corrosion resistance.',
                  images: [
                    {
                      original: aluminium6,
                      thumbnail: aluminium6
                    },
                    {
                      original: aluminium7,
                      thumbnail: aluminium7
                    },
                  ],
                }
              ],
      },
    ],
    products: "Our non-ferrous metals provide alternatives for applications where ferrous materials may not be suitable. From aluminum to copper and brass, our products offer unique properties tailored to the needs of industries such as electronics, construction, and automotive."
  },
  // Add other main categories with subcategories and nested subcategories similarly
];


const Clients = [
  {
    img: Client1
  },
  {
    img: Client2
  },
  {
    img: Client3
  },
  {
    img: Client4
  },
  {
    img: Client5
  },
  {
    img: Client6
  },
  {
    img: Client7
  },
  {
    img: Client8
  },
  {
    img: Client9
  },
  {
    img: Client10
  },
  {
    img: Client11
  },
  {
    img: Client12
  },
  {
    img: Client13
  },
  {
    img: Client14
  },
  {
    img: Client15
  },
  {
    img: Client16
  },
  {
    img: Client17
  },
  {
    img: Client18
  },
  {
    img: Client19
  },
  {
    img: Client20
  },
  {
    img: Client21
  },
  {
    img: Client22
  },
  {
    img: Client23
  },
  {
    img: Client24
  },
]

const OurClients =[
  {
    img: Client3
  },
  {
    img: C1
  },
  {
    img: C2
  },
  {
    img: C3
  },
  {
    img: C4
  },
  {
    img: C5
  },
  {
    img: C6
  },
  {
    img: C7
  },
  {
    img: C8
  },
  {
    img: C9
  },

]



const ProductRange = [
  {
    img: steel,
    title: "Steels",
    decription: "A comprehensive selection of high-quality steels for various industrial applications.",
  },
  {
    img: Handicraftt,
    title: "Handicrafts",
    decription: "Exquisite and unique handicrafts that reflect the rich cultural heritage of India.",
  },
  {
    img: Pipe,
    title: "Pipe and Pipe-fitting",
    decription: "Offering a wide range of pipes and fittings for diverse industrial needs.",
  },
  {
    img: Saftyitem,
    title: "Safety Items",
    decription: "Ensuring the well-being of your workforce with our top-notch safety products.",
  },
  {
    img: Fastener,
    title: "Fasteners",
    decription: "High-performance fasteners to meet the demands of industrial projects.",
  },
  {
    img: Wire,
    title: "Wire Mesh, Gratings, Perforated Sheets & Vibrating Screens",
    decription: "Precision-engineered products for diverse applications.",
  },
  {
    img: Rubber,
    title: "Rubber & Synthetic Rubber Products",
    decription: "Reliable and durable rubber solutions for industrial use.",
  },
  {
    img: Refactory,
    title: "Refractory Anchors",
    decription: "Essential components for high-temperature applications.",
  },
  {
    img: StainlessSteel,
    title: "Stainless Steel",
    decription: "Premium stainless steel products for various industries.",
  },
  {
    img: Hroil,
    title: "HR Coils, PPGI Coils, CR Coils",
    decription: "High-quality coils for different manufacturing needs.",
  },
  {
    img: NonFerrousMetals,
    title: "Non Ferrous Metals",
    decription: "Offering a wide range of non-ferrous metals to meet industry specifications.",
  },
  {
    img: Texttile,
    title: "Textiles",
    decription: "Providing textiles of superior quality for diverse applications.",
  },
  {
    img: InstaReel,
    title: "Insta Reels Making Accessories",
    decription: "Specialized accessories for the Insta Reels manufacturing industry.    ",
  },
  {
    img: Food,
    title: "Food Products",
    decription: "A variety of high-quality food products for international markets. ",
  },
  {
    img: Pump,
    title: "Pumps, Pump Machines and Spares",
    decription: "Reliable pumping solutions and spare parts for industrial use.",
  },
  {
    img: Cable,
    title: "Electrical Cables",
    decription: "Meeting the electrical connectivity needs with our range of cables.",
  },
]

const PackagingSolution = [
  {
    img: Pharmaceuticals,
    title: "Pharmaceuticals"
  },
  {
    img: Spirits,
    title: "Spirits & Beverages"
  },
  {
    img: Food,
    title: "Food & Condiments"
  },
  {
    img: Sweet,
    title: "Sweets & Confectionery"
  },
  {
    img: PersonalCare,
    title: "Personal care"
  },
  {
    img: Fashion,
    title: "Fashion & Apparel"
  },
  {
    img: ElectronicsandSoftware,
    title: "Electronics & Software"
  },
]

const SingleProduct1 = [
  {
    img: img1,
    title: " Ball, Gate, Globe Valves, NRV, Steams Valves, Stainer etc, in stainless steel..."
  },
  {
    img: img2,
    title: "Elbow, Bends, Tees, Couplings, Flanges, End Caps, Stub Ends, Unions..."
  },
  {
    img: img3,
    title: " Industrial Wears, Industrial Safety Wears, Safety Barriers, Road Safety..."
  },
  {
    img: img4,
    title: " Hardware, Screw, SS/MS/HT Fasteners, Self Drilling Screws, Wooden Screw..."
  },
  {
    img: img5,
    title: " Wire Mesh, Vibrating Screens, Stainless Steel Wire Mesh, Welded Wire Mesh..."
  },
  {
    img: img6,
    title: "  H.R& C.R sheet, plate, coil, strips, rods, round, square, hexagon, wire, flats..."
  },
  {
    img: img7,
    title: " Pipes, sheets, flats, pipes Enconel, nickel ,Titanium, inconel-600, ..."
  },
  {
    img: img8,
    title: " Submersible Pumps, Monoblock Pumps, Water Pumps,Centrifugal Pumps, ...."
  },
]

const SingleProduct2 = [
  {
    img: Pharmaceuticals,
    title: " Pharmaceuticals",
  },
  {
    img: Spirits,
    title: " Spirits & Beverages",
  },
  {
    img: Food,
    title: "Food & Condiments",
  },
  {
    img: img9,
    title: " Food & Condiments",
  },
  {
    img: Sweet,
    title: " Sweets & Confectionery",
  },
  {
    img: PersonalCare,
    title: "Personal care",
  },
  {
    img: Fashion,
    title: " Fashion & Apparel",
  },
  {
    img: ElectronicsandSoftware,
    title: " Electronics & Software",
  },
]

const SingleProduct3 = [
  {
    img: img10,
    title: " Submersible Pumps, Monoblock Pumps, Water Pumps,Centrifugal Pumps, ....",
  },
  {
    img: img11,
    title: " High Voltage Insulation Mats, HT Cables, LT Cables, Mining Cables, Marine....",
  },
  {
    img: img12,
    title: " V-belts, Chains, Sprockets, Gears & Couplings, Pulleys, Universal, ....",
  },
  {
    img: img13,
    title: "Security Seals Like Container Seals, Zip Lock Seals, Pattern Seals....",
  },
]

const WhyChooseUs = [
  {
    title: "Quality Assurance",
    description: "At Newkart Business Pvt. Ltd, quality is our foremost priority. We adhere to stringent quality control measures throughout our supply chain, ensuring that every product we offer meets or exceeds international quality standards. Our commitment to quality is reflected in the durability, performance, and reliability of the materials and products we provide. We work closely with trusted manufacturers and suppliers, conducting rigorous inspections to guarantee the excellence of our offerings. Choose us for a guarantee of uncompromised quality in every transaction.",
  },
  {
    title: "Global Reach",
    description: "With a strong and expansive network, Newkart Business Pvt. Ltd offers a truly global reach for your import and export needs. Our well-established connections span across continents, enabling us to efficiently navigate international trade dynamics. Whether you are looking to source products from diverse regions or expand your market presence worldwide, our global reach ensures that your business can thrive on the international stage. Partner with us to access a world of opportunities and establish a robust presence in the global marketplace.",
  },
  {
    title: "Customer Satisfaction",
    description: "Customer satisfaction is at the heart of our business philosophy. We prioritize building long-term relationships with our clients based on trust, transparency, and reliability. Our dedicated customer support team is committed to addressing your queries, providing timely updates, and ensuring a smooth and hassle-free experience. We value your feedback and continuously strive to exceed your expectations. Choose Newkart Business Pvt. Ltd for a partner who is genuinely invested in your success and satisfaction.",
  },
  {
    title: "Diverse Product Range",
    description: "Newkart Business Pvt. Ltd takes pride in offering a diverse and comprehensive product range that caters to a wide spectrum of industries. Whether you require raw materials, finished goods, or specialized components, we have a solution for every need. Our extensive portfolio is a testament to our commitment to being a one-stop destination for businesses seeking varied products. By choosing us, you gain access to a versatile array of materials and goods, streamlining your procurement process and enhancing operational efficiency.",
  },
]


export {
  ProductData,
  Clients,
  SingleProduct3,
  WhyChooseUs,
  SingleProduct2,
  SingleProduct1,
  PackagingSolution,
  ProductRange,
  OurClients
};