import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link, useLocation } from 'react-router-dom';
import Background4 from '../assets/images/bg_2.jpg';
import M1 from '../assets/images/home/TMT-bars.webp';
import M4 from '../assets/images/home/angle-home.webp';
import Background1 from '../assets/images/home/background.png';
import M5 from '../assets/images/home/beams-home.webp';
import Certified from '../assets/images/home/certified.png';
import M3 from '../assets/images/home/channel-home.webp';
import M6 from '../assets/images/home/flats-bars-home.webp';
import M2 from '../assets/images/home/sheets-and-plats-home.webp';
import Background10 from '../assets/images/steelbghome.jpg';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import TopBar from '../components/topbar';
import { Clients, PackagingSolution, ProductRange, WhyChooseUs } from '../data';


const Home = () => {
    const [openIndex, setOpenIndex] = useState(0); // Set the default open index to 0 (first question)

    // Function to toggle open/close state of FAQ item at given index
    const toggleItem = (index) => {
        setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const location = useLocation();


    useEffect(() => {
        if (location.hash) {
            const targetElement = document.getElementById(location.hash.slice(1));
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [location]);

    return (
        <>
            <TopBar />
            <Navbar />
            {/* < section className="ftco-section p-5 bg-half-light" id="backgroundimgset" >
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-2">
                        <div className="col-md-8 text-center heading-section ">
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}

                            >
                                <span className="subheading" style={{ fontSize: '16px',marginTop:"80px" }}>When you need
                                    particular steel at a
                                    particular place within a
                                    particular time, our role begins.</span>

                            </motion.div>

                            <motion.div
                                initial={{
                                    opacity: 0,
                                }}
                                whileInView={{
                                    opacity: 1,
                                    transition: {
                                        duration: 2
                                    }
                                }}

                            >
                                <h2 className="mb-4 text-white">We have everything in place to ensure that your orders reach your doorsteps within the expected time.</h2>
                            </motion.div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="services-wrap w-100">
                                <div className="img" style={{ backgroundImage: `url(${M1})` }}></div>
                                <div className="text">
                                    <h2>TMT & Rebars</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-wrap w-100">
                                <div className="img" style={{ backgroundImage: `url(${M2})` }}></div>
                                <div className="text">

                                    <h2>Sheets & Plates</h2>


                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-wrap w-100">
                                <div className="img" style={{ backgroundImage: `url(${M3})` }}></div>
                                <div className="text">
                                    <h2>Channels</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="services-wrap w-100 ">
                                <div className="img" style={{ backgroundImage: `url(${M4})` }}></div>
                                <div className="text">
                                    <h2>Angles</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-wrap w-100 ">
                                <div className="img" style={{ backgroundImage: `url(${M5})` }}></div>
                                <div className="text">

                                    <h2>Beams</h2>


                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="services-wrap w-100">
                                <div className="img" style={{ backgroundImage: `url(${M6})` }}></div>
                                <div className="text">
                                    <h2>Flat Bars</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section > */}
            <section className="hero-wrap js-fullheight" style={{ backgroundImage: `url(${Background1})`, height: "700px" }} data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-center" data-scrollax-parent="true">
                        <motion.div
                            className="col-lg-6 "
                            initial={{
                                opacity: 0,
                                x: -10
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: 1
                                }
                            }}

                        >
                            <div className="mt-5 mainslidertext">

                                <p className='m-0' style={{ fontWeight: "500" }}><Link to="/wedealin">Supplier of steel for B2B</Link></p>
                                <h1 className="mb-3 ">New Kart Business Pvt.</h1>
                                <h3 className="mb-2" style={{ fontSize: "20px" }}>WHEN YOU NEED PARTICULAR STEEL AT A PARTICULAR PLACE WITHIN A PARTICULAR TIME, OUR ROLE BEGINS.</h3>
                                <p className="mb-4 text-black" style={{ lineHeight: "23px" }}>We have everything in place to ensure that your orders reach your doorsteps within the expected time.</p>
                            </div>

                        </motion.div>


                        <motion.div
                            className="col-lg-6 "
                            initial={{
                                opacity: 0,
                                // if odd index card,slide from right instead of left
                                x: 10
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0, // Slide in to its original position
                                transition: {
                                    duration: 1 // Animation duration
                                }
                            }}

                        >
                            <div className="row heroservice">
                                <div className="col-md-4">
                                    <div className="services-wrap w-100">
                                        <div className="img" style={{ backgroundImage: `url(${M1})` }}></div>
                                        <div className="text">
                                            <h2>TMT & Rebars</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="services-wrap w-100">
                                        <div className="img" style={{ backgroundImage: `url(${M2})` }}></div>
                                        <div className="text">

                                            <h2>Sheets & Plates</h2>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="services-wrap w-100">
                                        <div className="img" style={{ backgroundImage: `url(${M3})` }}></div>
                                        <div className="text">
                                            <h2>Channels</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row heroservice">
                                <div className="col-md-4">
                                    <div className="services-wrap w-100 ">
                                        <div className="img" style={{ backgroundImage: `url(${M4})` }}></div>
                                        <div className="text">
                                            <h2>Angles</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="services-wrap w-100 ">
                                        <div className="img" style={{ backgroundImage: `url(${M5})` }}></div>
                                        <div className="text">

                                            <h2>Beams</h2>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="services-wrap w-100">
                                        <div className="img" style={{ backgroundImage: `url(${M6})` }}></div>
                                        <div className="text">
                                            <h2>Flat Bars</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="text-center">
                                <img src={Bannericon} />
                            </div> */}
                        </motion.div>
                    </div>
                </div>
            </section>



            <section className="ftco-section p-5" id="about-section">
                <div className="container">
                    <div className="row">
                        <motion.div
                            className="col-md-6 d-flex align-items-stretch"
                            initial={{
                                opacity: 0,
                                x: 10
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: 1
                                }
                            }}
                        >
                            <div className="about-wrap img w-100" style={{ backgroundImage: `url(${Background10})` }}>
                                <div className="icon d-flex align-items-center justify-content-center" style={{ height: '100px', boxShadow: 'none' }}><img src={Certified} style={{ height: '100px' }} /></div>
                            </div>
                        </motion.div>


                        <motion.div
                            className="col-md-6 py-5 pl-md-5"
                            initial={{
                                opacity: 0,
                                x: -10
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: 1
                                }
                            }}
                        >
                            <div className="row justify-content-center mb-4 pt-md-4">
                                <div className="col-md-12 heading-section ">
                                    <span className="subheading">Welcome to New Kart Business Pvt.</span>
                                    <h2 className="mb-4 text-white">Your Trusted Partner in Global Trade</h2>
                                    <div className="d-flex about">
                                        <h3 className="text-white">Elevate Your Business with Exceptional Import and Export Solutions</h3>
                                    </div>
                                    <p className="text-white">At Newkart Business Pvt. Ltd, we are dedicated to facilitating seamless import and export solutions, connecting businesses across the world. Based in India, we specialize in a diverse range of products to meet the ever-evolving demands of international trade.</p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </section>

            <div className="py-5 container">
                <div className=" text-center heading-section mb-5">
                    <span className="subheading text-center">Newkart Business Pvt. Ltd</span>
                    <h2 className="mb-1 text-center">Our Product Range Includes</h2>
                </div>

                <div className="row">
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlay
                        autoPlaySpeed={3000}
                        centerMode={false}
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite={false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024
                                },
                                items: 3,
                                partialVisibilityGutter: 40
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 2,
                                partialVisibilityGutter: 30
                            }
                        }}
                        rewind
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={2}
                        swipeable
                    >

                        {ProductRange.map((data, i) => {
                            return (
                                <div key={i} className="">
                                    <div className="services-wrap" style={{ height: "400px" }}>
                                        <div className="img immbg" style={{ backgroundImage: `url(${data.img})` }}></div>
                                        <div className="text">
                                            <h2>{data.title}</h2>
                                            <p>{data.decription}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
            </div>

            <div className="py-5 " id="packaging-section">
                <div className=" text-center heading-section mb-5">
                    <span className="subheading text-center">Packaging Solutions for Various Industries</span>
                    <h2 className="mb-1 text-center text-white">We also specialize in providing premium packaging materials tailored for the following industries</h2>
                </div>
                <div className="gallery">
                    {PackagingSolution.map((data, i) => {
                        return (
                            <div key={i} className="services-wrap">
                                <div className="image-container">
                                    <div className="image" style={{ backgroundImage: `url(${data.img})` }}></div>
                                    <div className="overlay"></div>
                                    <div className="text">
                                        <h2 className='bg-white py-2'>{data.title}</h2>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>

            <section className="ftco-section ftco-no-pt ftco-no-pb ftco-counter bg-secondary">
                <div className=" bg-secondary">
                    <div className=" no-gutters">
                        <div className="w-100 py-5 aside-stretch">
                            <div className="heading-section heading-section-white  p-3">
                                <span className="subheading text-center">Why Choose</span>
                                <h2 className="mb-4 text-center pb-3">Why Choose New Kart Business Private Ltd.?</h2>
                                <div className='whychoosussection'>
                                    {WhyChooseUs.map((data, index) => (
                                        <div key={index} className='faq-item'  >
                                            <div className="question "><svg style={{ marginRight: "15px" }} xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                                                <circle cx="8" cy="8" r="8" />
                                            </svg>{data.title}</div>
                                            <div className='answer'>
                                                <p>{data.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 text-center heading-section ">
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}
                            >
                                <span className="subheading">NEW KART BUSINESS PVT.</span>
                            </motion.div>

                            <motion.div
                                initial={{
                                    opacity: 0,
                                }}
                                whileInView={{
                                    opacity: 1,
                                    transition: {
                                        duration: 2
                                    }
                                }}
                            >
                                <h2 className="mb-1">Quality
                                    & Choice.</h2>

                            </motion.div>

                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}

                            >
                                <p>Sourcing high grade steel from the most trusted mills in India, we also specialize in tailor made offerings based on your specifications.</p>
                            </motion.div>
                        </div>
                    </div>
                    <div className="projectlogo">

                        {Clients.map((data, i) => {
                            return (
                                <div key={i} className="col-md-3">
                                    <div className="project2">
                                        <img src={data.img}>
                                        </img>
                                    </div>
                                </div>
                            )
                        })}


                    </div>
                </div>
            </section>

            <section className="ftco-intro">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <div className="img" style={{ backgroundImage: `url(${Background4})` }}>
                            <div className="overlay"></div>
                            <h2>Providing Personalized and High Quality Services</h2>
                            <p>Contact us today to discuss your import and export requirements, and let us be your partner in navigating the path to success.</p>
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 100
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0,
                                    transition: {
                                        duration: 1
                                    }
                                }}

                            >
                                <p className="mb-0"><Link to="/contact" className="btn btn-primary px-4 py-3">Contact Us</Link></p>

                            </motion.div>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Home