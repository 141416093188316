import React from 'react';
import { Link } from 'react-router-dom';

const Topbar = () => {

	return (
		<div id='topbar'>
			<div className="py-2 px-5 w-100" style={{ backgroundColor: '#040e26' }}>
				<div className="row">
					<div className="col-sm text-center text-md-left mb-md-0 mb-2 pr-md-4 d-flex topper align-items-center">
						<p className="mb-0 w-100">
						<span className="fa fa-paper-plane" style={{ color: '#fc5e28',fontSize:"16px" }}></span>&nbsp;&nbsp;
							<span className="text-white" style={{fontSize:"14px" }}>Mail us : enquiry@newkartbusiness.com</span>
						</p>
					</div>
					<div className="col-sm justify-content-center d-flex mb-md-0 mb-2">
					<div className="social-media">
							<p className="mb-0 d-flex">
								<a href="#" className="d-flex align-items-center justify-content-center mr-3"><span className="fa fa-facebook"><i className="sr-only">Facebook</i></span></a>
								<a href="#" className="d-flex align-items-center justify-content-center mr-3"><span className="fa fa-twitter"><i className="sr-only">Twitter</i></span></a>
								<a href="#" className="d-flex align-items-center justify-content-center mr-3"><span className="fa fa-instagram"><i className="sr-only">Instagram</i></span></a>
								{/* <a href="#" className="d-flex align-items-center justify-content-center"><span className="fa fa-dribbble"><i className="sr-only">Dribbble</i></span></a> */}
							</p>
						</div>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-4 d-flex topper align-items-center text-lg-right justify-content-end">
					<p className="mb-0 w-100" style={{display:"flex",alignItems:"center",justifyContent:"end"}}>
						<span className="fa fa-mobile" style={{ color: '#fc5e28',fontSize:"23px" }}></span>&nbsp;&nbsp;
							<span className="text-white" style={{fontSize:"14px" }}>Phone no : +91 82099 06543</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Topbar