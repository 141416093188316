import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../src/assets/css/style.css';
import './App.css';
import '../src/assets/css/responsive.css';
import 'animate.css'; // Moved this import to the top
import About from '../src/pages/about';
import Contact from '../src/pages/contact';
import Home from '../src/pages/home';
import OurTeam from './pages/ourteam';
import WeDealIn from './pages/wedealin';

function App() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/ourteam" element={<OurTeam />} />
                    <Route path="/wedealin" element={<WeDealIn />} />
                </Routes>
            </BrowserRouter>
            {/* <Home /> */}

            {/* back to top */}

            <div onClick={scrollToTop} className="backtotopicon">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5" />
                </svg>
            </div>

        </>
    );
}

export default App;
