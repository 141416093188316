import { motion } from "framer-motion";
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import rohitmittal from '../assets/images/Rohit Mittal.png';
import { default as Background1, default as Background4 } from '../assets/images/banner/back2.jpg';
import himanshusingh from '../assets/images/himamshu singh.jpg';
import jayeshpurohit from '../assets/images/team-9.jpg';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import TopBar from '../components/topbar';
import { Clients } from '../data';


const Ourteam = () => {

  const location = useLocation();


  useEffect(() => {
      if (location.hash) {
          const targetElement = document.getElementById(location.hash.slice(1));
          if (targetElement) {
              targetElement.scrollIntoView({ behavior: 'smooth' });
          }
      } else {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
  }, [location]);


  return (
    <>
      <TopBar />
      <Navbar />
      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Background1})`, backgroundPosition: "top" }} data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-start">
            <div className="col-md-9  pb-5">
              <p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="fa fa-chevron-right"></i></a></span> <span>Our Team<i className="fa fa-chevron-right"></i></span></p>
              <motion.div
                initial={{
                  opacity: 0,
                  x: -300
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1
                  }
                }}

              >
                <h1 className="mb-3 bread">Our Team</h1>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <div className="py-5">
        <div className=" container aboutmission" >
          <div className="text-center heading-section my-2 w-100">
            <span className="subheading">Workforce</span>
            <h3 className="mb-0 text-black" style={{fontSize:"20px"}}>"Our dedicated team of 20 professionals forms the backbone of Newkart Business Pvt. Ltd. Each member contributes unique skills and expertise, collectively fostering a culture of innovation and customer-centricity. Together, we are committed to upholding the company's values, delivering exceptional products and services, and realizing our vision of becoming a global leader in the import and export industry."</h3>
          </div>
        </div>
      </div>

      <div class="text-center heading-section ">
              <span class="subheading">Company Name</span>
              <h2>Our Team</h2>
            </div>

            <div className="container">
        <div className="ourteamdirector">
          <div className="directorimg">
            <img src={rohitmittal} />
            <div className="directorname">
              <h5>Rohit Mittal</h5>
              <p>Managing Director</p>
            </div>
          </div>
          <div className="ourteamdirectorsub">
            <div className="text-center heading-section ">
              <span className="subheading">New Kart Business Pvt.</span>
              <h2>Managing Director</h2>
            </div>
            <p className="text-justify directordetails">&ldquo;Rohit Mittal, an accomplished engineer with 8 years of industry expertise, brings a wealth of knowledge to the role of Managing Director. His career has been marked by strategic vision and leadership, propelling the company towards new heights. With a keen understanding of industry trends, Rohit oversees the overall direction and growth strategies of Newkart.&rdquo;</p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="ourteamdirector">
          <div className="directorimg">
            <img src={jayeshpurohit} />
            <div className="directorname">
              <h5>Jayesh Purohit</h5>
              <p>Director, Marketing & Sales</p>
            </div>
          </div>
          <div className="ourteamdirectorsub">
            <div className="text-center heading-section ">
              <span className="subheading">New Kart Business Pvt.</span>
              <h2>Director - Marketing & Sales</h2>
            </div>
            <p className="text-justify directordetails">Jayesh Purohit, an engineer with 8 years of specialized experience in sales and marketing, serves as the Director overseeing these crucial domains. His career has been characterized by successful market penetration strategies and a deep understanding of customer needs. Jayesh plays a pivotal role in shaping the company's market presence and ensuring customer satisfaction.</p>
          </div>
        </div>
      </div>

     

      <div className="container">
        <div className="ourteamdirector">
          <div className="directorimg">
            <img src={himanshusingh} />
            <div className="directorname">
              <h5>Himanshu Singh</h5>
              <p>Director - Operations & SCM</p>
            </div>
          </div>
          <div className="ourteamdirectorsub">
            <div className="text-center heading-section ">
              <span className="subheading">New Kart Business Pvt.</span>
              <h2>Director - Operations & SCM</h2>
            </div>
            <p className="text-justify directordetails">&ldquo;Himanshu Singh, a seasoned finance professional with 7 years of proven expertise, leads as the Director of Operations & Supply Chain Management. His background in finance equips him with a meticulous approach to operations, ensuring efficiency and excellence. Himanshu plays a key role in streamlining processes, optimizing the supply chain, and enhancing overall operational performance.&rdquo;</p>
          </div>
        </div>
      </div>


      <section className="ftco-section mt-3">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 text-center heading-section ">
              <motion.div
                initial={{
                  opacity: 0,
                  y: 100
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1
                  }
                }}
              >
                <span className="subheading">NEW KART BUSINESS PVT.</span>
              </motion.div>

              <motion.div
                initial={{
                  opacity: 0,
                }}
                whileInView={{
                  opacity: 1,
                  transition: {
                    duration: 2
                  }
                }}
              >
                <h2 className="mb-1">Quality
                  & Choice.</h2>

              </motion.div>

              <motion.div
                initial={{
                  opacity: 0,
                  y: 100
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1
                  }
                }}

              >
                <p>Sourcing high grade steel from the most trusted mills in India, we also specialize in tailor made offerings based on your specifications.</p>
              </motion.div>
            </div>
          </div>
          <div className="projectlogo">

            {Clients.map((data, i) => {
              return (
                <div key={i} className="col-md-3">
                  <div className="project2">
                    <img src={data.img}>
                    </img>
                  </div>
                </div>
              )
            })}


          </div>
        </div>
      </section>

      <section className="ftco-intro">
        <div className="">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="img" style={{ backgroundImage: `url(${Background4})` }}>
                <div className="overlay"></div>
                <h2>Providing Personalized and High Quality Services</h2>
                <p>Contact us today to discuss your import and export requirements, and let us be your partner in navigating the path to success.</p>
                <p className="mb-0"><Link to="/contact" className="btn btn-primary px-4 py-3">Contact Us</Link></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Ourteam