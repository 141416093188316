import { motion } from "framer-motion";
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Background5 from '../assets/images/about-3.jpg';
import Background2 from '../assets/images/about/delivering-solutions-supply-top-1.webp';
import { default as Background1, default as Background4 } from '../assets/images/banner/back.jpg';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import TopBar from '../components/topbar';
import { OurClients } from "../data";



const About = () => {

  const location = useLocation();


  useEffect(() => {
      if (location.hash) {
          const targetElement = document.getElementById(location.hash.slice(1));
          if (targetElement) {
              targetElement.scrollIntoView({ behavior: 'smooth' });
          }
      } else {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
  }, [location]);
  
  return (
    <>
      <TopBar />
      <Navbar />
      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Background1})` }} data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-start">
            <div className="col-md-9  pb-5">
              <p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="fa fa-chevron-right"></i></a></span> <span>About Us <i className="fa fa-chevron-right"></i></span></p>
              <motion.div
                initial={{
                  opacity: 0,
                  x: -300
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1
                  }
                }}

              >
                <h1 className="mb-3 bread">About Us</h1>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section" >
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-stretch">
              <div className="about-wrap img w-100" style={{ backgroundImage: `url(${Background2})` }}>
              </div>
            </div>
            <motion.div
              className="col-md-6 pl-md-5"
              initial={{
                opacity: 0,
                x: 400
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 1
                }
              }}
            >
              <div className="row justify-content-center mb-4 pt-md-4 ">
                <div className="col-md-12 heading-section ">
                  <span className="subheading">About Us</span>
                  <h2 className="mb-4">Welcome to New Kart Business Pvt.</h2>
                  <p>Founded with a legacy of 15 years in the traditional steel industry, Newkart Business Pvt. Ltd emerged just a year ago with a visionary goal – to be the ultimate one-stop solution for both industrial and household needs. In our inaugural year, we achieved a remarkable turnover of over 15 crores, a testament to our unwavering commitment to delivering the best quality products at optimal prices with swift delivery lead times. </p>
                  <p>Comprising a dynamic team of young and experienced entrepreneurs, our journey is fueled by the desire to contribute back to society, sharing the knowledge and gains accumulated over the years. At Newkart, excellence is not just a goal; it's our commitment to you."</p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>


      <div className="py-0">
        <div className=" container aboutmission" >
          <div className="text-center heading-section my-0 w-100">
            <span className="subheading">Our Vision</span>
            <h3 className="mb-4 text-black" style={{ fontSize: "20px" }}>"To be the global leader in providing innovative, sustainable, and comprehensive solutions, enhancing industries and households alike."</h3>
          </div>



        </div>

      </div>


      <section className="ftco-section ftco-no-pt ftco-no-pb ftco-counter">
        <div className="img image-overlay" style={{ backgroundImage: `url(${Background5})` }}></div>
        <div className="container">
          <div className="row no-gutters">
            <div className="col-md-6 py-0 bg-secondary aside-stretch">
              <div className="heading-section heading-section-white  pl-md-0 py-md-5 pr-md-5 w-100 aboutmission">
                <span className="subheading mb-4" style={{ fontSize: "35px" }}>Our Mission</span>
                <div className="missiondata">
                  <p className="text-white mb-4" style={{ fontSize: "16px" }}><strong>1. Customer-Centric Excellence</strong><br />"Dedicated to exceeding customer expectations by consistently delivering top-tier products and services, we aim to forge lasting partnerships, ensuring satisfaction and success for our clients."</p>
                  <p className="text-white mb-4" style={{ fontSize: "16px" }}><strong>2. Innovation for Impact</strong><br />"We strive to be at the forefront of innovation, continuously introducing cutting-edge technologies and sustainable practices. Our mission is to drive positive change, not only in the products we offer but also in the industries we serve."</p>
                  <p className="text-white " style={{ fontSize: "16px" }}><strong>3. Empowering Communities</strong><br />"Committed to social responsibility, we actively engage in initiatives that uplift and empower communities. Through ethical business practices and philanthropic endeavors, we aim to make a meaningful and lasting impact on society, creating a better future for all."</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="row">
              </div>
            </div>
          </div>
        </div >
      </section >



      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className=" text-center heading-section ">
              <span className="subheading">NEW KART BUSINESS PVT.</span>
              <h2 className="mb-4">Our Clients</h2>
              <p>We have created a significant database of construction companies and contractors or are directly associated with government authorities or corporations like NHAI, Northern Railways (IRCON, KRCL), CPWD, NHPC, AAI, BRO, DGMAP, NBCC along with other major road, rail, hydel and telecom corporations in India. Not only this, we are also proud to be associated with several reputed and renowned state-owned and reputed private manufacturers of India.</p>
            </div>
          </div>
          <div className="projectlogo">

            {OurClients.map((data, i) => {
              return (
                <div key={i} className="col-md-3">
                  <div className="project2">
                    <img style={{height:"120px"}} src={data.img}>
                    </img>
                  </div>
                </div>
              )
            })}


          </div>
        </div>
      </section>

      <section className="ftco-intro">
        <div className="">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="img" style={{ backgroundImage: `url(${Background4})` }}>
                <div className="overlay"></div>
                <h2>Providing Personalized and High Quality Services</h2>
                <p>Contact us today to discuss your import and export requirements, and let us be your partner in navigating the path to success.</p>
                <p className="mb-0"><Link to="/contact" className="btn btn-primary px-4 py-3">Contact Us</Link></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default About